import React from 'react';

export const DimensionsContext = React.createContext<{
  width: number;
  height: number;
}>({ height: 0, width: 0 });

export const DimensionsConsumer = DimensionsContext.Consumer;
export const DimensionsProvider = DimensionsContext.Provider;

export const useDimensions = () => React.useContext(DimensionsContext);
