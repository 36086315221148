import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { sectionSettingsCssProperties } from '../Settings/useSettingsSections';
import settingsParams, {
  uninitializedVisibilitySettingsTrueValue,
} from '../settingsParams';
import stylesParams from '../stylesParams';
import { ISection, SectionTypes } from '../types';
import { useWidgetSections } from '../Widget/useWidgetSections';
import { VisibilityKey } from './types';
import { useVisibilityExperiment } from './useVisibilityExperiment';

export function useSectionVisibilityCheck() {
  const { isVisibilityStyleParamsEnabled } = useVisibilityExperiment();
  const styles = useStyles();
  const sections = useWidgetSections();

  const sectionShouldBeVisibleHandler = (
    keyInSectionSettings: keyof ISection,
    sectionType: SectionTypes,
    styleParamCssProperty: keyof typeof stylesParams,
  ) => {
    const section = sections.find(
      (currSection) => currSection.type === sectionType,
    );

    const settingsValue = section![keyInSectionSettings];
    if (typeof settingsValue === 'boolean') {
      return settingsValue;
    }
    if (isVisibilityStyleParamsEnabled) {
      return styles
        .getStylesForAllBreakpoints()
        .some(({ booleans }) => booleans[styleParamCssProperty]);
    } else {
      // @ts-expect-error
      return settingsValue === uninitializedVisibilitySettingsTrueValue;
    }
  };

  return {
    shouldSectionBeVisible(sectionType: SectionTypes) {
      return sectionShouldBeVisibleHandler(
        'visible',
        sectionType,
        sectionSettingsCssProperties[sectionType].isVisible,
      );
    },
    shouldSectionBookButtonBeVisible(sectionType: SectionTypes) {
      return sectionShouldBeVisibleHandler(
        'bookButton',
        sectionType,
        sectionSettingsCssProperties[sectionType].isBookButtonVisible,
      );
    },
  };
}
