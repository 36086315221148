import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { ISection, SectionTypes } from '../types';

export type visibilitySectionBookButtonStyleParams = keyof Pick<
  typeof stylesParams,
  | 'sectionTitleBookButtonVisibility'
  | 'sectionSchedulingBookButtonVisibility'
  | 'sectionContactBookButtonVisibility'
  | 'sectionDescriptionBookButtonVisibility'
  | 'sectionDetailsBookButtonVisibility'
  | 'sectionGalleryBookButtonVisibility'
  | 'sectionPluginBookButtonVisibility'
  | 'sectionPolicyBookButtonVisibility'
>;

export type visibilitySectionStyleParams = keyof Pick<
  typeof stylesParams,
  | 'sectionTitleVisibility'
  | 'sectionSchedulingVisibility'
  | 'sectionContactVisibility'
  | 'sectionDescriptionVisibility'
  | 'sectionDetailsVisibility'
  | 'sectionGalleryVisibility'
  | 'sectionPluginVisibility'
  | 'sectionPolicyVisibility'
>;

export type visibilitySectionDividerStyleParams = keyof Pick<
  typeof stylesParams,
  | 'sectionTitleDivider'
  | 'sectionSchedulingDivider'
  | 'sectionContactDivider'
  | 'sectionDescriptionDivider'
  | 'sectionDetailsDivider'
  | 'sectionGalleryDivider'
  | 'sectionPluginDivider'
  | 'sectionPolicyDivider'
>;

export interface SectionCssProperties {
  isVisible: visibilitySectionStyleParams;
  isBookButtonVisible: visibilitySectionBookButtonStyleParams;
  hasDivider: visibilitySectionDividerStyleParams;
}

export const sectionSettingsCssProperties: Record<
  SectionTypes,
  SectionCssProperties
> = {
  [SectionTypes.TITLE_TAGLINE]: {
    isVisible: 'sectionTitleVisibility',
    isBookButtonVisible: 'sectionTitleBookButtonVisibility',
    hasDivider: 'sectionTitleDivider',
  },
  [SectionTypes.SCHEDULING]: {
    isVisible: 'sectionSchedulingVisibility',
    isBookButtonVisible: 'sectionSchedulingBookButtonVisibility',
    hasDivider: 'sectionSchedulingDivider',
  },
  [SectionTypes.CONTACT]: {
    isVisible: 'sectionContactVisibility',
    isBookButtonVisible: 'sectionContactBookButtonVisibility',
    hasDivider: 'sectionContactDivider',
  },
  [SectionTypes.DESCRIPTION]: {
    isVisible: 'sectionDescriptionVisibility',
    isBookButtonVisible: 'sectionDescriptionBookButtonVisibility',
    hasDivider: 'sectionDescriptionDivider',
  },
  [SectionTypes.DETAILS]: {
    isVisible: 'sectionDetailsVisibility',
    isBookButtonVisible: 'sectionDetailsBookButtonVisibility',
    hasDivider: 'sectionDetailsDivider',
  },
  [SectionTypes.GALLERY]: {
    isVisible: 'sectionGalleryVisibility',
    isBookButtonVisible: 'sectionGalleryBookButtonVisibility',
    hasDivider: 'sectionGalleryDivider',
  },
  [SectionTypes.PLUGIN]: {
    isVisible: 'sectionPluginVisibility',
    isBookButtonVisible: 'sectionPluginBookButtonVisibility',
    hasDivider: 'sectionPluginDivider',
  },
  [SectionTypes.POLICY]: {
    isVisible: 'sectionPolicyVisibility',
    isBookButtonVisible: 'sectionPolicyBookButtonVisibility',
    hasDivider: 'sectionPolicyDivider',
  },
};

export const useSettingsSections = (): ISection[] => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const isPluginInstallationOnServicePageEnabled = experiments.enabled(
    'specs.bookings.enablePluginInstallationOnServicePage',
  );

  let sections: ISection[] = settings
    .get(settingsParams.sections)
    .filter((section) => section);

  if (sections.map(({ type }) => type).indexOf(SectionTypes.GALLERY) === -1) {
    sections.push({
      type: SectionTypes.GALLERY,
      visible: true,
      bookButton: false,
    });
  }

  if (!isPluginInstallationOnServicePageEnabled) {
    sections = sections.filter(
      (section) => section.type !== SectionTypes.PLUGIN,
    );
  } else {
    if (sections.map(({ type }) => type).indexOf(SectionTypes.PLUGIN) === -1) {
      sections.push({
        type: SectionTypes.PLUGIN,
        visible: true,
        bookButton: false,
      });
    }
  }

  return sections;
};
